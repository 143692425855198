export default{
    title: 'Calendar',
    sync: 'Sync Google Calendar',
    stop: 'Stop syncing',
    settings: 'Settings',
    activeSync: 'Active syncing with',
    syncPaused: 'Syncing has stopped. Please re-authorize the calendar by clicking the following link.',
    notFunctioning: 'If the syncing has stopped,',
    googleNoEdit:'You can\'t edit google calendar events from here',

    edit:'Edit appointment',
    add: 'Create new appointment',
    cancelled: 'Cancelled appointment'
}
