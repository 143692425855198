function deepMergeWithSpread(obj1, obj2) {
    const result = { ...obj1 };

    for (let key in obj2) {
        if (obj2.hasOwnProperty(key)) {
            if (obj2[key] instanceof Object && obj1[key] instanceof Object) {
                result[key] = deepMergeWithSpread(obj1[key], obj2[key]);
            } else {
                result[key] = obj2[key];
            }
        } else {
            result[key] = obj2[key];
        }
    }

    return result;
}

export const DonutDefaults = ({id, height, labels, colors, tooltipValueDescription, ...rest}) => deepMergeWithSpread({
    chart: { id: '13pie-' + id, height: height ?? 300, type: 'donut' },
    labels,
    colors,
    tooltip: {
        y: {
            formatter: (value) => {
                return `${value} ${tooltipValueDescription}`
            }
        },
    },
    legend: {position: 'bottom'},
    stroke: {
        width: 0
    },
}, rest);

export const PieChartDefaults = ({ labels, id, colors, tooltipValueDescription, width, ...rest }) => deepMergeWithSpread({
    chart: {id, width: width ?? 250, type: 'pie'},
    labels,
    colors,
    tooltip: {
        y: {
            formatter: (value) => {
                return `${value} ${tooltipValueDescription}`
            }
        }
    },
    legend: {position: 'bottom'},
    stroke: {width: 0},
    responsive: [
        {breakpoint: 480, options: {chart: {width: 220, height: 300}, legend: {position: 'bottom'}}},
        // This is added as a 'fake' breakpoint that will never apply
        // otherwise the chart will not render correctly (it'll crash!)
        {breakpoint: 9001, options: {}}
    ],
}, rest);

export const BarChartDefaults = ({id, height, colors, xAxisCategories, tooltipValueDescription, ...rest}) => deepMergeWithSpread({
    colors,
    chart: {
        type: 'bar',
        toolbar: {
            show: false,
            tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset: false,
                customIcons: []
            }
        },
        stacked: false,
    },
    plotOptions: {
        bar: {borderRadius: 10, dataLabels: {position: 'top'},},
        dataLabels: {
            enabled: false
        },
    },
    yaxis: {
        forceNiceScale: true
    },
    tooltip: {
        y: {
            formatter: (val, opts) => {
                return `${val} ${tooltipValueDescription}`
            }
        }
    },
    dataLabels: {
        formatter: (val, opts) => {
            return `${val} ${tooltipValueDescription}`
        },
        offsetY: 2,
        style: {
            fontSize: '10px',
            fontWeight: '300',
            fontFamily: 'inherit'
        }
    },
    xaxis: {
        categories: xAxisCategories
    },
    responsive: [
        {
            breakpoint: 540,
            options: {
                chart: {stacked: true},
                plotOptions: {bar: {borderRadius: 3}},
                dataLabels: {style: { fontSize: '5px' }}
            },
        },
        // This is added as a 'fake' breakpoint that will never apply
        // otherwise the chart will not render correctly (it'll crash!)
        { breakpoint: 9001,  options: {} }
    ]
}, rest);