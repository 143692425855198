
export default {
    add: 'Προσθήκη πελάτη',
    form: 'Φόρμα δημιουργίας πελάτη',
    base: 'Βασικά στοιχεία',
    smallQuestionnaire: 'Σύντομο ερωτηματολόγιο',
    measures: 'Μετρήσεις',
    measurements: {
        view: 'Προβολή μετρήσεων'
    },
    goals: 'Στόχοι',
    diets: 'Διατροφές',
    viewModes: {
        table: 'Προβολή πελατών σε μορφή πίνακα',
        cards: 'Προβολή πελατών σε μορφή καρτών'
    },
    options:{
        editTags: 'Επεξεργασία ετικετών',
        editQuestionnaires: 'Επεξεργασία ερωτηματολογίων',
        massImport: 'Μαζική εισαγωγή',
        massExport: 'Μαζική εξαγωγή'
    },
    empty:{
        title: 'ΔΕΝ ΒΡΕΘΗΚΑΝ ΠΕΛΑΤΕΣ',
        subtitle: 'Δεν έχετε δημιουργήσει ακόμα κάποιο πελάτη.',
        addFirst: 'ΠΡΟΣΘΗΚΗ ΤΟΥ ΠΡΩΤΟΥ ΣΑΣ ΠΕΛΑΤΗ'
    },
    activity: {
        title: 'Δραστηριότητα πελατών',
        noAthletes: 'Δεν υπάρχουν πελάτες σε αυτή την κατηγορία',
        active: 'Ενεργοί πελάτες',
        semiActive: 'Ημι-ενεργοί πελάτες',
        inactive: 'Ανενεργοί πελάτες'
    },
    statistics: {
        title: 'Στατιστικά πελατών',
        lastUpdate: 'Τελευταία ενημέρωση',
        totalAthletes: 'Σύνολο πελατολογίου',
        totalDiets: 'Σύνολο διατροφικών πλάνων πελατών',
        period: 'Σύνολο διατροφικών πλάνων πελατών',
        gender: {
            title: 'Φύλο πελατών',
            subtitle: 'Κατανομή του φύλου του πελατολογίου σας'
        },
        customizable: {
            title: 'Στατιστικά με ορισμένη περίοδο',
        },
        yearly: {
            title: 'Έσοδα έτους',
            subtitle: 'Τα έσοδα / ραντεβού του κάθε μήνα για το έτος {year} (Σημείωση: για τους επόμενους μήνες, το διάγραμμα εμφανίζει τα κανονισμένα έσοδα / ραντεβού).'
        },
        appointment: 'Ραντεβού',
        cancelledAppointment: 'Ακυρωμένα ραντεβού',
        appointmentIncome: 'Έσοδα από ραντεβού',
        cancelledAppointmentIncome: 'Ακυρωμένα έσοδα (ραντεβού που ακυρώθηκαν)',
        income: {
            period: 'Έσοδα αυτή την περίοδο',
            cancelled: 'Ακυρωμένα έσοδα αυτή την περίοδο'
        },
        appointments: {
            title: 'Ραντεβού',
            period: 'Ραντεβού αυτή την περίοδο',
            cancelled: 'Ακυρωμένα ραντεβού αυτή την περίοδο'
        },
        dietPlans: {
            title: 'Δημιουργία διατροφικών πλάνων',
            subtitle: 'Δείτε τον ρυθμό δημιουργίας νέων διατροφικών πλάνων σε σύγκριση με τον τελευταίο μήνα',
        },
        clients: {
            title: 'Νέοι πελάτες',
            subtitle: 'Δείτε τον ρυθμό δημιουργίας πελατών σε σύγκριση με τον τελευταίο μήνα'
        },
        secondCards: {
            income: 'Έσοδα του μήνα έως σήμερα',
            forecast: 'Εκτιμώμενα έσοδα για τον υπόλοιπο μήνα',
            appointments: 'Ραντεβού αυτό τον μήνα',
            cancelledAppointments: 'Ακυρωμένα ραντεβού αυτό τον μήνα',
        },
        thisMonth: 'Αυτός ο μήνας',
        lastMonth: 'Προηγούμενος μήνας'
    }
}