export default{


    watch:{
        'api.filters.search'(val){
            this.api.page = 1;
            this.getData(this.api.url);
        }
    },
    data(){
        return{
            // make everything nested inside "api" object
            api: {
                extraTrackedFields: {},
                successData: false,
                url: null,
                timeout: null,
                page: 1,
                query: {
                    offset: 0,
                    limit: 10,
                    sorting: undefined
                },
                filters:{
                  search: ''
                },
                rows: [],
                count: 0,
                loading: true
            }

        }
    },

    methods:{

        // gets data from an API endpoint (url) paginated. It saves the data in the "data" variable, c
        getData(){
            this.api.loading = true;
            this.api.successData = false;
            this.api.query.offset = (this.api.page -1) * this.api.query.limit;

            const params = {
                offset:  this.api.query.offset,
                limit: this.api.query.limit,
                sorting: this.api.query.sorting,
                ...this.extraParams
            };


            // add all filters from the filters object.
            for(let key of Object.keys(this.api.filters)){
                if(this.api.filters[key] !== ''){
                    params[key] = this.api.filters[key]
                }
            }

            return this.$axios.get(this.api.url, {params: params}).then(result => {

                this.api.rows = result.data.rows;
                this.api.count = result.data.count;
                this.api.successData = true;
                // set the extra tracked fields values.
                for(let [key, value] of Object.entries(this.api.extraTrackedFields)){
                    this[key] = result.data[value]
                }
            }).catch(e => {
                this.$notify({
                    group: 'athlisis-notifications',
                    type:'error',
                    duration:5000,
                    title: this.$t('errors.title'),
                    text: this.$t('errors.text')
                });
            }).finally(()=>{
                this.api.loading = false;
            });
        }
    }
}