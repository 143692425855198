<template>
  <div class="">
    <div class="row">
      <div class="col-md-6">
        <b-form-group id="input-firstName" :label="`${$t('forms.fields.name')}*`" label-for="firstName">
          <b-form-input id="firstName" v-model="newClient.firstName"
                        :placeholder="$t('forms.placeholders.athlete.name')"
                        required type="text"
          />
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group id="input-lastName" :label="`${$t('forms.fields.lastName')}*`" label-for="lastName">
          <b-form-input id="lastName" v-model="newClient.lastName"
                        :placeholder="$t('forms.placeholders.athlete.lastName')"
                        required type="text"
          ></b-form-input>
        </b-form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <b-form-group id="input-birthDate" :label="`${$t('forms.fields.birthDate')}*`" label-for="birthDate">
          <date-picker v-model="newClient.birthDate" :first-day-of-week="1" :lang="elDatePicker"
                       :placeholder="$t('forms.placeholders.birthDate')"
                       format="DD-MM-YYYY"
          ></date-picker>
        </b-form-group>
      </div>

      <div class="col-md-6">
        <b-form-group id="input-gender" :label="`${$t('forms.fields.gender.title')}*`" label-for="gender">
          <b-form-select v-model="newClient.sex" required>
            <option value="null">{{ $t('forms.fields.gender.choose') }}</option>
            <option value="M">{{ $t('forms.fields.gender.male') }}</option>
            <option value="F">{{ $t('forms.fields.gender.female') }}</option>
          </b-form-select>
        </b-form-group>
      </div>

    </div>
    <div class="row">
      <div class="col-md-6">
        <b-form-group id="input-email" :label="$t('forms.fields.email')" label-for="email">
          <b-form-input id="email" v-model="newClient.email"
                        type="text"
                        @change="newClient.email = newClient.email.replaceAll(' ','').toLowerCase()"
          ></b-form-input>
        </b-form-group>
      </div>
      <div class="col-md-6">
        <b-form-group id="input-mobileNum" :label="$t('forms.fields.mobile') " label-for="mobileNum">
          <b-form-input id="mobileNum" v-model="newClient.mobileNum" type="text"
          ></b-form-input>
        </b-form-group>
      </div>

      <div class="col-md-12 mb-2">
        <label>{{ $t('forms.fields.sessionCost') }}:</label>
        <b-input-group append="€">
          <b-form-input v-model="newClient.sessionCost" step="1" type="number"/>
        </b-input-group>
      </div>

    </div>
    <div class="text-right">
      <b-button :variant="variant" size="md" @click="saveNewCustomer">
        {{ $t('pages.home.addAthlete') }}
      </b-button>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import DatePicker from 'vue2-datepicker'

export default {

  props: {
    variant: {
      type: String,
      default: "primary"
    }
  },
  components: {DatePicker},
  data() {
    return {
      newClient: this.getBaseAthlete,
      error: null
    }
  },
  created() {
    this.newClient = this.getBaseAthlete
  },
  computed: {
    getBaseAthlete() {
      return {
        firstName: '',
        lastName: '',
        sex: null,
        birthDate: '',
        email: '',
        mobileNum: '',
        sessionCost: 0,
        athleteShortQuestionnaire: {
          q1: new Date(),
          q2: null,
          q3: null,
          q4: null,
          q5: null,
          q6: null,
          q7: null,
          q8: null,
          q9: null,
          q10: null,
          q11: null,
          q12: null,
          q13: null,
          q13_1: null,
          q14: null,
          q14_1: null,
          q15: null,
          q15_1: null,
          q16: null,
          q16_1: null,
          q17: null,
          q17_1: null,
          q18: null,
          q18_1: null,
          q19: null,
          q19_1: null,
          q20: null,
          q20_1: null,
          q21: null,
          q21_1: null,
          q22: null,
          q22_1: null,
          q23: null,
          q23_1: null,
          q24: null,
          q24_1: null,
          q25: null,
          q25_1: null,
          q26: null,
          q26_1: null,
          q27: null,
          q28: null,
          q29: null,
          q30: null,
          q31: null,
          q32: null,
          q33: null,
          q34: null,
          q35: null,
        },
        measurements: {
          weight: null,
          height: null,
          bmi: null,
          fatPercentage: null
        },
      }
    }
  },
  methods: {

    saveNewCustomer() {
      this.error = null;
      if (!this.newClient.firstName || !this.newClient.lastName || !this.newClient.sex || !this.newClient.birthDate) {
        this.error = this.$t('pages.athlete.invalidData');
        return;
      }

      this.newClient.birthDate = new Date(this.newClient.birthDate).setHours(12);

      axios.post(`${process.env.VUE_APP_API}/athlete/`, this.newClient).then(result => {
        this.$emit('clientCreated', result.data.athlete);
        this.createNewClient = false;
        this.newClient = structuredClone(this.getBaseAthlete);
        this.$notify({
          group: 'athlisis-notifications',
          type: 'success',
          title: this.$t('success.title'),
          text: this.$t('success.text'),
        });
        this.$store.dispatch('athlete/cacheAthleteNames', true);
      }).catch(e => {
        this.isCreatingAthlete = false;
        this.$notify({
          group: 'athlisis-notifications',
          type: 'error',
          title: this.$t('errors.title'),
          text: this.$t('errors.text'),
        });
      });
    },
  }

}
</script>
