<template>

  <div id="mass-import">

    <div v-if="step === 0" class="step-0">

      <div v-html="$t('massImport.content')" />

      <vue-dropzone v-show="showZone" id="dropzone" ref="uploadLogo" :options="dropzoneOptions"
                    class="mt-3 mb-3" @vdropzone-success="completed"
      ></vue-dropzone>
    </div>

    <div v-if="step === 1" class="step-1">
      <div v-html="$t('massImport.results', results)"/>

      <div class="text-center">
        <b-button size="xs" variant="primary" @click="closeModal">{{ $t('close') }}</b-button>
      </div>

    </div>

  </div>

</template>
<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  components: {vueDropzone: vue2Dropzone},
  data() {
    return {
      step: 0,
      showZone: true,
      dropzoneOptions: {
        url: `${process.env.VUE_APP_API}/general/csv-upload`,
        maxFilesize: 1,
        headers: {"x-access-token": JSON.parse(window.localStorage.getItem('auth.token'))},
        maxFiles: 1,
        dictDefaultMessage: this.$t('global.dropCsv')
      },
      results: null,
    }
  },
  methods: {
    completed(file, res) {
      this.$emit('imported');
      this.step++;
      this.$notify({
        group: 'athlisis-notifications', type: 'success',
        title: this.$t('success.title'),
        text: this.$t('success.text')
      });
      this.results = res;
    },
    closeModal() {
      this.$emit('closeModal')
    }
  }

}
</script>
