<template>
  <b-tabs content-class="mt-1" nav-class="nav-tabs nav-bordered">
    <b-tab :title="$t('cloud.plan.tabs.0')">
      <table v-if="!loadingDietPlan" id="dietPlan-table" class="table table-responsive table-borderless"
             style="table-layout: fixed; "
      >
        <tbody>
        <tr v-for="(row, idx1) in days" :key="idx1">

          <th class="table-meal-day-name">

            {{ dayNames[idx1] }}
          </th>

          <td v-for="(idx2) in getValidMealsOrder(dietPlan)" :id="'meal-'+idx1 +''+ idx2" :key="idx2"
              class="table-meal"
          >
            <div class="row-header day">

              {{ getMealName(dietPlan, days[idx1][idx2].type) }}
            </div>
            <div class="row-content">
              <div v-for="mealfood in days[idx1][idx2].foods" :key="mealfood.id">
                <div :class="'meal-category-' + mealfood.category" class="table-meal-food">
                  <!--                      {{mealfood}}-->
                  {{ formatName(mealfood) }}: {{ formatMealFoodUnits(mealfood) }}
                </div>
              </div>

              <div v-if="days[idx1][idx2].notes && !dietPlan.isTextOnly" class="meal-category-notes">

                <strong>{{ $t('notes') }}:</strong> {{ days[idx1][idx2].notes }}
              </div>
              <div v-if="days[idx1][idx2].notes && dietPlan.isTextOnly" class="meal-category-text-only-notes"
                   v-html="days[idx1][idx2].notes"
              />

            </div>

          </td>
        </tr>
        </tbody>
      </table>
    </b-tab>
    <b-tab :title="$t('cloud.plan.tabs.1')">
      <b-overlay :show="loadingDietPlan" rounded="sm">
        <b-tabs>
          <b-tab :title="$t('food.stats.macros')">
            <div v-if="!loadingDietPlan">
              <div class="row">.
                <div class="col-md-12">
                  <div class="row mb-3 p-2">
                    <div class="col-lg-3 col-md-6 p-1">
                      <div class="shadow-sm text-center p-3" style="border-radius:12px;">
                        <h4>{{ getAverage('calories') }} {{ $t('tools.append.kcal') }}</h4>
                        <span class="text-muted" v-html="$t('cloud.average.kcal')"/>
                      </div>

                    </div>
                    <div class="col-lg-3 col-md-6 p-1">
                      <div class="shadow-sm text-center p-3" style="border-radius:12px;">
                        <h4>{{ getAverage('proteins') }} {{ $t('tools.append.g') }}</h4>
                        <span class="text-muted" v-html="$t('cloud.average.proteins')"/>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 p-1">
                      <div class="shadow-sm text-center p-3" style="border-radius:12px;">
                        <h4>{{ getAverage('carbs') }} {{ $t('tools.append.g') }}</h4>
                        <span class="text-muted" v-html="$t('cloud.average.carbs')"/>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-6 p-1">
                      <div class="shadow-sm text-center p-3" style="border-radius:12px;">
                        <h4>{{ getAverage('fats') }} {{ $t('tools.append.g') }}</h4>
                        <span class="text-muted" v-html="$t('cloud.average.fats')"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <h5 style="min-height: 35px;"> {{ $t('micro.cal') }}</h5>
                  <apexchart :options="getOptions('CALORIES')" :series="getSeries('CALORIES')" height="300px"
                             type="line"
                  ></apexchart>
                </div>
                <div class="col-md-4">
                  <h5><img alt="icon-apple" src="@assets/images/icons/icon-protein-dark.png" width="45px"/>
                    {{ $t('micro.carbs') }} / <img alt="icon-apple" src="@assets/images/icons/icon-amulouxa-dark.png"
                                                   width="45px"
                    />
                    {{ $t('micro.proteins') }}</h5>
                  <apexchart :options="getOptions('CARBS-PROTEINS')" :series="getSeries('CARBS-PROTEINS')"
                             height="300px"
                             type="line"
                  ></apexchart>
                </div>
                <div class="col-md-4">
                  <h5>{{ $t('food.stats.stats') }}</h5>
                  <apexchart :options="pieOptions" :series="getSeries('FOOD-PIE')" height="300px" type="pie"
                  ></apexchart>

                </div>
              </div>

              <div class="row">
                <div class="col-md-4">
                  <h5><img alt="icon-apple" src="@assets/images/icons/icon-protein-dark.png" width="45px"/>
                    {{ $t('micro.proteins') }}</h5>
                  <apexchart :options="getOptions('PROTEINS')" :series="getSeries('PROTEINS')" height="300px"
                             type="line"
                  ></apexchart>
                </div>
                <div class="col-md-4">
                  <h5><img alt="icon-apple" src="@assets/images/icons/icon-amulouxa-dark.png" width="45px"/>
                    {{ $t('micro.carbs') }}</h5>
                  <apexchart :options="getOptions('CARBS')" :series="getSeries('CARBS')" height="300px" type="line"
                  ></apexchart>
                </div>
                <div class="col-md-4">
                  <h5><img alt="icon-apple" src="@assets/images/icons/icon-fats-dark.png" width="45px"/>
                    {{ $t('micro.fats') }}</h5>
                  <apexchart :options="getOptions('FATS')" :series="getSeries('FATS')" height="300px" type="line"
                  ></apexchart>
                </div>
              </div>

            </div>
          </b-tab>
          <b-tab :title="$t('pages.recipes.label.micro')">
            <div v-if="!loadingDietPlan" class="tabs">
              <b-tabs justified nav-class="nav-tabs nav-bordered" vertical>

                <b-tab v-for="(day,index) in dayNames" :key="day" :title="day">

                  <div class="nutrient-wrapper pl-md-5">
                    <table class="micronutrients-table">
                      <tr>
                        <th>{{ $t('pages.recipes.label.micro') }}</th>
                        <th>{{ $t('quantity') }}</th>
                      </tr>
                      <tr v-for="(nutrient,index2) of microNutrientFields"
                          v-if="days[index].totalStats.microNutrients && days[index].totalStats.microNutrients[nutrient]"
                          :key="index + '-'+index2"
                          class="nutrient-item"
                      >
                        <td>{{ microNutrientTranslation[nutrient] }}:</td>
                        <td>{{ +days[index].totalStats.microNutrients[nutrient].toFixed(2) }}</td>


                      </tr>
                    </table>
                  </div>


                </b-tab>
              </b-tabs>

            </div>

          </b-tab>


        </b-tabs>

      </b-overlay>
    </b-tab>

    <b-tab :title="$t('cloud.discussion')">
      <cloud-listing-comments :listing-id="listingId"></cloud-listing-comments>
    </b-tab>
  </b-tabs>

</template>


<script>

import Vue from "vue";
import CloudListingComments from "@components/cloud/preview/CloudListingComments";
import DietPlanMixin from "@src/mixins/DietPlanMixin";
import MealMixin from "@src/mixins/MealMixin";
import {mapState} from "vuex";
import {convertFlattenedToNested} from "@utils/util";
import {convertCloudMealFoodToSystemMealFoodFormat} from "@utils/cloudFood";

export default {

  components: {CloudListingComments},
  props: {
    dietPlan: Object,
    listingId: String
  },
  mixins: [DietPlanMixin, MealMixin],
  computed: {
    ...mapState({
      foods: state => state.food._foods,
    })
  },
  data() {
    return {
      days: [[], [], [], [], [], [], []],
      loadingDietPlan: true,
      foodStatistics: null,
      pieOptions: {
        chart: {
          width: 250,
          type: 'pie',
        },
        labels: [
          this.$t('food.stats.fresh'),
          this.$t('food.stats.amylouxes'),
          this.$t('food.stats.fats'),
          this.$t('food.stats.animal'),
          this.$t('food.stats.complex'),
          this.$t('food.stats.drinks')
        ],
        colors: ['#1abc9c', '#3155A6', '#f7b84b', '#f1556c', '#d3d3d3', '#0891b2'],
        tooltip: {
          y: {
            formatter: (value) => {
              return value + ' ' + this.$t('foods')
            },
          },
        },
        legend: {
          position: 'bottom'
        },
        stroke: {
          width: 0
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 220
            },
            legend: {
              position: 'bottom'
            }
          }
        },
          // This is added as a 'fake' breakpoint that will never apply
          // otherwise the chart will not render correctly (it'll crash!)
          { breakpoint: 9001,  options: {} }
        ]
      },
      diagrams: {
        stroke: {
          width: 2,
          curve: 'smooth'
        }
      },
    }
  },
  mounted() {
    if (!this.dietPlan.dietPlanMealOptions) {
      this.dietPlan.dietPlanMealOptions = {
        totalMeals: 5,
        mealsOrder: [0, 1, 2, 3, 4, 5, 6],
        mealNames: {0: null, 1: null, 2: null, 3: null, 4: null, 5: null, 6: null}
      }
    }
    this.initialDietPlanFormatting();
  },

  methods: {
    initialDietPlanFormatting() {
      if (!this.dietPlan || !this.dietPlan.meals) return [];

      const meals = this.dietPlan.meals;

      for (let i = 0; i < 7; i++) {
        let dayMeals = meals.filter(x => x.day === i);
        for (let j = 0; j < dayMeals.length; j++) {
          this.days[i].push(dayMeals.filter(x => x.type === j)[0]);
        }
      }
      this.displayAndCalculateFoods();
    },

    // gets an a-cloud plan and converts it into a local-system format
    // by parsing the food and applying the right metadata in the right format.
    formatMealsIntoCasualFormat(){
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < this.dietPlan.dietPlanMealOptions.totalMeals; j++) {
          // convert all to match our casual system parsing format.
          const formattedFoods = [];
          for (let meal of this.days[i][j].foods) {
            formattedFoods.push(convertCloudMealFoodToSystemMealFoodFormat(meal));
          }
          Vue.set(this.days[i][j], 'foods', formattedFoods);
        }
      }
    },
    displayAndCalculateFoods() {
      this.formatMealsIntoCasualFormat();
      let foodStats = [0, 0, 0, 0, 0, 0];
      for (let i = 0; i < 7; i++) {
        let dayStats = {calories: 0, carbs: 0, proteins: 0, fats: 0, microNutrients: {}};
        for (let j = 0; j < this.dietPlan.dietPlanMealOptions.totalMeals; j++) {
          let totalStats = {calories: 0, carbs: 0, proteins: 0, fats: 0, microNutrients: {}};

          this.orderFoods(i, j);

          for (let meal of this.days[i][j].foods) {
            foodStats[meal.category - 1] += 1;
            this.prepareMealFoodData(meal, meal.food);
            this.aggregateStatsFromMealFoodData(totalStats, meal);
          }

          // sums all changes from the meal into the particular day.
          // A day can have 1-7 meals, so we need to sum them all.
          this.addToDayStats(dayStats, totalStats);
          // set meal
          Vue.set(this.days[i][j], 'statistics', totalStats);
        }
        // set the day stats.
        Vue.set(this.days[i], 'totalStats', dayStats);
      }
      this.foodStatistics = foodStats;
      this.loadingDietPlan = false;

    },
    getAverage(type) {
      let total = 0;
      for (let day of this.days) {
        total += parseFloat(day.totalStats[type]);
      }
      // only count days with calories > 0 (don't count empty days)
      let totalDays = this.days.filter(x => parseFloat(x.totalStats['calories']) > 0).length

      return (total / totalDays).toFixed(2);
    },
    getOptions(type) {
      let color;
      let tooltipVal;
      // let customFormatter;

      if (type === 'CALORIES') {
        color = ['#3155A6'];
        tooltipVal = this.$t('tools.append.kcal');
      }

      if (type === 'CARBS') {
        color = ['#1abc9c'];
        tooltipVal = this.$t('tools.append.g');
      }

      if (type === 'PROTEINS') {
        color = ['#f7b84b'];
        tooltipVal = this.$t('tools.append.g');
      }

      if (type === 'FATS') {
        color = ['#f1556c'];
        tooltipVal = this.$t('tools.append.g');
      }

      if (type === 'CARBS-PROTEINS') {
        color = ['#1abc9c', '#f7b84b'];
        tooltipVal = this.$t('tools.append.g');
      }

      let diagramOpt = {
        chart: {
          toolbar: {
            show: false,
            tools: {
              download: false,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: []
            },
          },
        },
        tooltip: {
          y: {
            formatter: (value) => {
              return value.toFixed(0) + tooltipVal
            },
          },
        },
        stroke: this.diagrams.stroke,
        xaxis: {
          categories: this.showNames ? this.dayNamesAlt : this.dayNamesShort,
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            }
          }
        },
        animations: {
          enabled: false
        }
      };

      diagramOpt.chart.id = type;
      diagramOpt.colors = color;
      return diagramOpt;
    },
    getSeries(type) {
      if (type === 'CALORIES') {
        return [{
          name: this.$t('micro.cal'),
          data: [...this.days.map(x => {
            return x.totalStats.calories
          })]
        }]
      } else if (type === 'PROTEINS') {
        return [{
          name: this.$t('micro.proteins'),
          data: [...this.days.map(x => {
            return x.totalStats.proteins
          })]
        }]
      } else if (type === 'CARBS') {
        return [{
          name: this.$t('micro.carbs'),
          data: [...this.days.map(x => {
            return x.totalStats.carbs
          })]
        }]
      } else if (type === 'FATS') {
        return [{
          name: this.$t('micro.fats') + ': ',
          data: [...this.days.map(x => {
            return x.totalStats.fats
          })]
        }]
      } else if (type === 'CARBS-PROTEINS') {
        return [{
          name: this.$t('micro.carbs'),
          data: [...this.days.map(x => {
            return x.totalStats.carbs
          })]
        },
          {
            name: this.$t('micro.proteins'),
            data: [...this.days.map(x => {
              return x.totalStats.proteins
            })]
          }]
      } else if (type === 'FOOD-PIE') {
        return this.foodStatistics
      }

    },
  }
}
</script>
