<template>
  <b-button :type="type" variant="primary" class="btn btn-success" :disabled="disabled" @click="$emit('click')">
    <div class="d-flex align-items-center">
      <span class="icon-check mr-1"></span> {{ text || $t('saveChanges') }}
    </div>
  </b-button>
</template>

<script>
export default{
  props:{
    type: {
      type: String,
      default: null
    },
    text: {
      type: String
    },
    disabled:{
      type: Boolean,
      default: false
    }
  },
}
</script>