import store from '@state/store'

export default [
  {
    path: '/',
    name: 'home',
    component: () => lazyLoadView(import('@views/home')),
    meta: {
      authRequired: true,
      hideBreadcrumbs: true
    },
    props: (route) => ({ user: store.state.auth.currentUser || {} }),
  },
  {
    path: '/login',
    name: 'login',
    component: () => lazyLoadView(import('@views/auth/login')),
    meta: {
      layout: 'auth',
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' });
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => lazyLoadView(import('@views/auth/register')),
    meta: {
      layout: 'auth',
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/confirm-account',
    name: 'confirm-account',
    component: () => lazyLoadView(import('@views/auth/confirm')),
    meta: {
      layout: 'auth',
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/recover-password',
    name: 'recover-password',
    component: () => lazyLoadView(import('@views/auth/recover-password')),
    meta: {
      layout: 'auth',
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/mobile/recover-password',
    name: 'mobile-recover-password',
    meta:{
      layout: 'auth'
    },
    component: () => lazyLoadView(import('@views/auth/mobile-recover-password'))
  },
  {
    path: '/shared-questionnaire',
    name: 'athletes-shared-questionnaire',
    meta:{
      layout: 'auth'
    },
    component: () => lazyLoadView(import('@views/public/shared-questionnaire'))
  },
  {
    path: '/shared-recipe/:recipeId',
    name: 'shared-recipe',
    meta:{
      layout: 'auth'
    },
    component: () => lazyLoadView(import('@views/public/shared-recipe.vue'))
  },
  {
    path: '/google-calendar-redirect',
    name: 'google-calendar-redirect',
    meta:{
      layout: 'auth'
    },
    component: () => lazyLoadView(import('@views/public/google-calendar-redirect'))
  },
  {
    path: '/coupon-usage',
    name: 'public-coupon-usage',
    meta:{
      layout: 'auth'
    },
    component: () => lazyLoadView(import('@views/public/coupon-usage'))
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => lazyLoadView(import('@views/auth/forgotPassword')),
    meta: {
      layout: 'auth',
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      layout: 'auth',
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('auth/logOut'); // logout user
        store.dispatch('food/reset'); // clear food state
        store.dispatch('tags/reset'); // clear tag state

        next({name: 'login'});
      },
    },
  },
  {
    path:'/admin',
    name: 'admin-home',
    component: () => lazyLoadView(import('@views/admin/dashboard')),
    meta:{
      authRequired:true,
      adminRequired:true
    }
  },
  {
    path:'/admin/notifications',
    name: 'admin-notifications',
    component: () => lazyLoadView(import('@views/admin/notifications')),
    meta:{
      authRequired:true,
      adminRequired:true
    }
  },
  {
    path: '/admin/reports',
    name: 'admin-user-reports',
    component: () => lazyLoadView(import('@views/admin/UserReports')),
    meta:{
      authRequired: true,
      adminRequired: true
    }
  },
  {
    path: '/admin/coupons',
    name: 'admin-coupons',
    component: () => lazyLoadView(import('@views/admin/Coupons')),
    meta:{
      authRequired: true,
      adminRequired: true
    }
  },
  {
    path: '/admin/library',
    name: 'admin-library',
    component: () => lazyLoadView(import('@views/admin/Library')),
    meta:{
      authRequired: true,
      adminRequired: true
    }
  },
  {
    path:'/admin/users',
    name: 'admin-users',
    component: () => lazyLoadView(import('@views/admin/userList')),
    meta:{
      authRequired:true,
      adminRequired:true
    }
  },
  {
    path:'/admin/transactions',
    name: 'admin-transactions',
    component: () => lazyLoadView(import('@views/admin/transactionsList')),
    meta:{
      authRequired:true,
      adminRequired:true
    }
  },
  {
    path: '/user',
    name: 'Profile',
    component: () => lazyLoadView(import('@views/user/profile')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/user/subscription',
    name: 'user-subscription',
    component: () => lazyLoadView(import('@views/user/userSubscription')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/user/subscription/:userTransactionId',
    name: 'pay-transaction',
    component: ()=> lazyLoadView(import('@views/user/payTransaction')),
    meta:{
      authRequired: true
    }
  },
  {
    path: '/library',
    name: 'library',
    component: () => lazyLoadView(import('@views/general/scienceLibrary')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/terms',
    name: 'terms',
    component: () => lazyLoadView(import('@views/general/terms')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/training',
    name: 'training',
    component: () => lazyLoadView(import('@views/general/training')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/library/:articleId',
    name: 'article',
    component: () => lazyLoadView(import('@views/general/article')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => lazyLoadView(import('@views/general/faq')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => lazyLoadView(import('@views/general/calendar')),
    meta: {
      authRequired: true,
      hideBreadcrumbs: true,
      hideFooter: true,
    },
  },
  {
    path: '/chat',
    name: 'chat',
    component: () => lazyLoadView(import('@views/chat/Chat')),
    meta: {
      layout: 'chat',
      authRequired: true,
    },
  },
  {
    path: '/cloud',
    name: 'cloud',
    component: () => lazyLoadView(import('@views/cloud/CloudHome')),
    meta: {
      layout:'cloud',
      authRequired: true,
    },
  },
  {
    path: '/cloud/users/:userId',
    name: 'cloud-user',
    component: () => lazyLoadView(import('@views/cloud/CloudUser')),
    meta: {
      layout:'cloud',
      authRequired: true,
    },
  },
  {
    path: '/cloud/users/:userId/listings',
    name: 'cloud-user-listings',
    component: () => lazyLoadView(import('@views/cloud/CloudUserListings')),
    meta: {
      layout:'cloud',
      authRequired: true,
    },
  },
  {
    path: '/cloud/listings/:listingId',
    name: 'cloud-listing',
    component: () => lazyLoadView(import('@views/cloud/CloudListing')),
    meta: {
      layout:'cloud',
      authRequired: true,
    },
  },
  {
    path: '/cloud/diet-plans',
    name: 'cloud-diet-plans',
    component: () => lazyLoadView(import('@views/cloud/CloudDietPlans')),
    meta: {
      layout:'cloud',
      authRequired: true,
    },
  },
  {
    path: '/cloud/meals',
    name: 'cloud-meals',
    component: () => lazyLoadView(import('@views/cloud/CloudMealTemplates')),
    meta: {
      layout:'cloud',
      authRequired: true,
    },
  },
  {
    path: '/cloud/foods',
    name: 'cloud-foods',
    component: () => lazyLoadView(import('@views/cloud/CloudFoods')),
    meta: {
      layout:'cloud',
      authRequired: true,
    },
  },
  {
    path: '/cloud/recipes',
    name: 'cloud-recipes',
    component: () => lazyLoadView(import('@views/cloud/CloudRecipes')),
    meta: {
      layout:'cloud',
      authRequired: true,
    },
  },
  {
    path: '/athletes',
    name: 'athletes',
    component: () => lazyLoadView(import('@views/athletes/athleteList')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/athletes/statistics',
    name: 'athletes-stats',
    component: () => lazyLoadView(import('@views/athletes/athleteStatistics')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/athletes/add',
    name: 'add-athlete',
    component: () => lazyLoadView(import('@views/athletes/addAthlete')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/athletes/:athleteId',
    name: 'view-athlete',
    component: () => lazyLoadView(import('@views/athletes/athlete/athleteBase')),
    meta:{
      authRequired: true
    }
  },
  {
    path: '/athletes/:athleteId/diet-plan/:dietPlanId',
    name: 'athlete-diet-plan',
    component: () => import('@views/athletes/athlete/dietPlans/athleteDietPlanView'),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/user/change-password',
    name: 'change-password',
    component: () => lazyLoadView(import('@views/user/changePasswordForm')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/user/todo-list',
    name: 'user-todo',
    component: () => lazyLoadView(import('@views/user/toDoListForm')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/user/company',
    name: 'company',
    component: () => lazyLoadView(import('@views/user/userCompanyDetailsForm')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/user/tags',
    name: 'user-tags',
    component: () => lazyLoadView(import('@views/user/userTags')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/user/print-templates',
    name: 'print-templates',
    component: () => lazyLoadView(import('@views/user/PrintTemplates')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/user/print-templates/:templateId',
    name: 'edit-print-template',
    component: () => lazyLoadView(import('@views/user/PrintTemplatesEditor')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/user/questionnaires',
    name: 'questionnaires',
    component: () => lazyLoadView(import('@views/user/questionnaires/QuestionnaireList')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/user/questionnaires/:questionnaireId',
    name: 'edit-questionnaire',
    component: () => lazyLoadView(import('@views/user/questionnaires/EditQuestionnaire')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/user/purchase-athlisis',
    name: 'purchase-athlisis',
    component: () => import('@views/user/purchaseAthlisis'),
    meta:{
      layout: 'auth',
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.state.auth.currentUser.isPremium) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    }
  },
  {
    path: '/auth/paused',
    name: 'auth-paused',
    component: () => import('@views/auth/paused'),
    meta:{
      layout: 'auth',
      authRequired: true,
    }
  },
  {
    path: '/notifications',
    name: 'user-notifications',
    component: () => lazyLoadView(import('@views/notifications/notificationList')),
    meta: {
      authRequired: true,
    },
  },
  {
    path: '/foods',
    name: 'Τρόφιμα',
    component: () => lazyLoadView(import('@views/food/foodList')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/diet-plans',
    name: 'diet-plans',
    component: () => lazyLoadView(import('@views/dietPlan/list/dietPlanListView')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/meals',
    name: 'meals',
    component: () => lazyLoadView(import('@views/dietPlan/list/mealTemplateView')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/recipes',
    name: 'recipes',
    component: () => lazyLoadView(import('@views/recipes/RecipeList')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/recipes/:recipeId',
    name: 'recipe',
    component: () => lazyLoadView(import('@views/recipes/Recipe')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/diet-plans/statistics',
    name: 'diet-plan-statistics',
    component: () => lazyLoadView(import('@views/dietPlan/dietPlanStatistics')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/diet-plans/notes',
    name: 'diet-plan-notes',
    component: () => lazyLoadView(import('@views/dietPlan/notes/NoteTemplates')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/diet-plans/notes/:noteId',
    name: 'edit-diet-plan-notes',
    component: () => lazyLoadView(import('@views/dietPlan/notes/EditNote')),
    meta: {
      authRequired: true
    }
  },
  {
    path: '/diet-plans/:dietPlanId',
    name: 'diet-plan',
    component: () => import('@views/dietPlan/details/dietPlanDetailsView'),
    meta: {
        authRequired: true
      }
  },
  {
    path: '/500',
    name: '500',
    meta:{
      layout: 'auth',
    },
    component: require('@views/_timeout').default,
  },
  {
    path: '/404',
    name: '404',
    meta:{
      layout: 'auth',
    },
    component: require('@views/_404').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true,
  },

  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
]

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
function lazyLoadView(AsyncView) {
  const AsyncHandler = () => ({
    component: AsyncView,
    meta:{
      layout: 'auth',
    },
    // A component to use while the component is loading.
    loading: require('@views/_loading').default,
    // Delay before showing the loading component.
    // Default: 200 (milliseconds).
    delay: 400,
    // A fallback component in case the timeout is exceeded
    // when loading the component.
    error: require('@views/_timeout').default,
    // Time before giving up trying to load the component.
    // Default: Infinity (milliseconds).
    timeout: 10000,
  })

  return Promise.resolve({
    functional: true,
    render(h, { data, children }) {
      // Transparently pass any props or children
      // to the view component.
      return h(AsyncHandler, data, children)
    },
  })
}
