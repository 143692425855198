var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('apexchart',{attrs:{"height":"350px","options":_vm.activeCustomersChartOptions,"series":_vm.activeCustomerSeries}}),_c('div',{staticClass:"tw-text-center"},[_c('activity-explanation',{staticClass:"tw-max-w-full md:tw-w-[85%] tw-mx-auto tw-text-center"})],1),_c('b-tabs',{attrs:{"nav-class":"nav-tabs nav-bordered -tw-mt-6","fill":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('b-tab',{attrs:{"title":_vm.$t('pages.athletes.activity.semiActive')}}),_c('b-tab',{attrs:{"title":_vm.$t('pages.athletes.activity.inactive')}}),_c('b-tab',{attrs:{"title":_vm.$t('pages.athletes.activity.active')}})],1),(_vm.activeList.length > 0)?_c('div',[_c('div',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.activeList,"fields":[
               {
                 key: 'firstName',
                 label: _vm.$t('forms.fields.name'),
                 sortable: true
               },
               {
                 key: 'lastName',
                 label: _vm.$t('forms.fields.lastName'),
                 sortable: true
               },
               {
                 key: 'email',
                 label: _vm.$t('forms.fields.email'),
                 sortable: true
               },
               {
                 key: 'mobileNum',
                 label: _vm.$t('forms.fields.mobile'),
               },
               {
                 key: 'phoneNum',
                 label: _vm.$t('forms.fields.phone'),
               },
               {
                 key: 'actions',
                 label: _vm.$t('actions'),
                 class: 'text-center'
               }
           ],"per-page":_vm.perPage,"current-page":_vm.currentPage},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('b-button',{attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.onRowClicked(data.item)}}},[_vm._v(" "+_vm._s(_vm.$t('global.view'))+" ")])]}}],null,false,1844697322)})],1),_c('div',{staticClass:"max-md:text-center md:tw-flex tw-justify-between tw-items-center"},[_c('download-csv',{staticClass:"btn btn-outline-primary mr-2 mb-0 max-md:tw-text-center",attrs:{"data":_vm.activeList.map(x => ({
        [this.$t('forms.fields.name')]: x.firstName,
        [this.$t('forms.fields.lastName')]: x.lastName,
        [this.$t('forms.fields.email')]: x.email,
        [this.$t('forms.fields.mobile')]: x.mobileNum,
        [this.$t('forms.fields.phone')]: x.phoneNum,
        [this.$t('forms.fields.sessionCost')]: (x.sessionCost ?? 0) + '€',

      })),"name":_vm.fileName}},[_c('i',{staticClass:"fe-download"}),_vm._v(" "+_vm._s(_vm.$t('save'))+" ("+_vm._s(_vm.fileName)+") ")]),_c('div',{staticClass:"pagination pagination-rounded justify-content-center mt-0"},[_c('b-pagination',{attrs:{"total-rows":_vm.activeList.length,"per-page":_vm.perPage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)]):_c('div',[_c('div',{staticClass:"tw-text-center tw-mt-5"},[_c('p',[_vm._v(_vm._s(_vm.$t('pages.athletes.activity.noAthletes')))])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }