<template>
  <div>
    <apexchart height="350px" :options="activeCustomersChartOptions" :series="activeCustomerSeries" />

    <div class="tw-text-center">
      <activity-explanation class="tw-max-w-full md:tw-w-[85%] tw-mx-auto tw-text-center" />
    </div>

    <b-tabs v-model="active" nav-class="nav-tabs nav-bordered -tw-mt-6" fill>
      <b-tab :title="$t('pages.athletes.activity.semiActive')" />
      <b-tab :title="$t('pages.athletes.activity.inactive')" />
      <b-tab :title="$t('pages.athletes.activity.active')" />
    </b-tabs>

    <div v-if="activeList.length > 0">
      <div>
        <b-table striped hover
                 :items="activeList"
                 :fields="[
                 {
                   key: 'firstName',
                   label: $t('forms.fields.name'),
                   sortable: true
                 },
                 {
                   key: 'lastName',
                   label: $t('forms.fields.lastName'),
                   sortable: true
                 },
                 {
                   key: 'email',
                   label: $t('forms.fields.email'),
                   sortable: true
                 },
                 {
                   key: 'mobileNum',
                   label: $t('forms.fields.mobile'),
                 },
                 {
                   key: 'phoneNum',
                   label: $t('forms.fields.phone'),
                 },
                 {
                   key: 'actions',
                   label: $t('actions'),
                   class: 'text-center'
                 }
             ]"
                 :per-page="perPage"
                 :current-page="currentPage">
          <template #cell(actions)="data">
            <b-button variant="outline-primary" size="sm" @click="onRowClicked(data.item)">
              {{ $t('global.view') }}
            </b-button>
          </template>
        </b-table>
      </div>

      <div class="max-md:text-center md:tw-flex tw-justify-between tw-items-center">
        <download-csv :data="activeList.map(x => ({
          [this.$t('forms.fields.name')]: x.firstName,
          [this.$t('forms.fields.lastName')]: x.lastName,
          [this.$t('forms.fields.email')]: x.email,
          [this.$t('forms.fields.mobile')]: x.mobileNum,
          [this.$t('forms.fields.phone')]: x.phoneNum,
          [this.$t('forms.fields.sessionCost')]: (x.sessionCost ?? 0) + '€',

        }))" :name="fileName" class="btn btn-outline-primary mr-2 mb-0 max-md:tw-text-center"><i
            class="fe-download"
        /> {{ $t('save') }} ({{ fileName }})
        </download-csv>
        <div class="pagination pagination-rounded justify-content-center mt-0">
          <b-pagination v-model="currentPage" :total-rows="activeList.length" :per-page="perPage" />
        </div>
      </div>

    </div>
    <div v-else>
      <div class="tw-text-center tw-mt-5">
        <p>{{ $t('pages.athletes.activity.noAthletes') }}</p>
      </div>
    </div>

  </div>

</template>

<script>
import {mapState} from "vuex";
import { DonutDefaults } from "@utils/apexcharts.config";
import ActivityExplanation from "@components/icons/ActivityExplanation.vue";
import downloadCsv from "vue-json-csv";
import athleteList from "@views/athletes/athleteList.vue";

export default {
  components: {downloadCsv, ActivityExplanation},
  data(){
    return {
      active: 0,
      currentPage: 1,
      perPage: 8,
    }
  },

  computed: {
    ...mapState({
      activeAthletes: state => state.athlete._activeAthletes,
      athletes: state => state.athlete.athleteNames,
    }),

    fileName(){
      const names = ['semi_active', 'inactive', 'active'];
      return `${names[this.active]}_${this.formatDateWithoutHour(new Date())}.csv`;
    },

    activeList() {
      let result = [];
      if (this.active === 2) {
        result = this.athletes.filter(x => this.activeAthletes?.activeUserIds.includes(x.id));
      } else if (this.active === 0) {
        result = this.athletes.filter(x => this.activeAthletes?.semiActiveUserIds.includes(x.id));
      } else {
        result = this.athletes.filter( x=> !this.activeAthletes?.activeUserIds.includes(x.id) && !this.activeAthletes?.semiActiveUserIds.includes(x.id));
      }
      return result;
    },
    activeCustomersChartOptions() {
      return DonutDefaults({
        id: 'activeCustomersChart',
        colors: ['#e5994e', '#dc3545', '#22c55e'],
        tooltipValueDescription: this.$t('global.athletes'),
        labels: [this.$t('pages.athletes.activity.semiActive'), this.$t('pages.athletes.activity.inactive'), this.$t('pages.athletes.activity.active')],
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              this.active = config.dataPointIndex;
            }
          }
        }
      });
    },

    activeCustomerSeries() {
      const activeUserIdsCount = this.activeAthletes?.activeUserIds?.length || 0;
      const semiActiveUserIdsCount = this.activeAthletes?.semiActiveUserIds?.length || 0;
      const totalAthletesCount = this.athletes.length;

      const inactiveUserIdsCount = totalAthletesCount - activeUserIdsCount - semiActiveUserIdsCount;

      return [
        semiActiveUserIdsCount,
        inactiveUserIdsCount,
        activeUserIdsCount
      ]
    },
  },

  methods:{
    onRowClicked(row){
      this.$router.push('/athletes/' + row.id);
      this.triggerActiveAthletesModal(false);
    },
  },

  created() {
    this.$store.dispatch('athlete/cacheActiveAthletes');
    this.$store.dispatch('athlete/cacheAthleteNames');
  },
}
</script>