export default{
    overview: 'Client overview',
    back: 'Back to client diet plans',
    tabs: {
        base: {
            title: 'Information',
            subtitle: 'Base contact information'
        },
        progress: {
            title: 'Monitoring Sheet',
            subtitle: 'Track progress per appointment'
        },
        dietPlans: {
            title: 'Diet plans',
            subtitle: 'Weekly diet plans',
        },
        measures: {
            title: 'Measurements',
            subtitle: 'Physiometrics, vitamins, hematological...'
        },
        sports: {
            title: 'Exercise & Supplements',
            subtitle: 'Exercise and suggested supplements'
        },
        questionnaire: {
            title: 'Questionnaire',
            subtitle: 'Short, long and custom questionnaires'
        },
        mobile: {
            title: 'Mobile App',
            subtitle: 'Calendar view - goals'
        },
        files: {
            title: 'Files',
            subtitle: 'List of saved files'
        },
    },
    age: 'Age',
    year:'years',
    years:'years old',
    appointmentHistory: 'Appointment history',
    delete: 'Delete client',
    disconnectApp: 'You first have to disconnect the app.',
    goals: {
        title: '',
        completed: 'Completed goals',
        nonCompleted: 'Pending goals',
        goal: 'Goal',
        goals: 'Goals',
        goalPlaceholder: 'Create new goal',
        targetDate: 'Target date',
        completionDate: 'Completion date'
    },
    events: {
        future: 'Future',
        past: 'Completed',
        cancelled: 'cancelled',
        approved: 'confirmed'
    },
    absolutePercentage: 'Absolute change',
    percentage: 'Percentage change',
    weightChange: 'Weight change',
    fatChange: 'Fat change',
    fromFirst: '(from the first appointment)',
    addProgressItem: 'Create an entry',
    editProgressItem: 'Edit entry',
    invalidData: 'Please complete all the required fields to create the client.',
    shareMeasurements: 'Share measurements to mobile app',

    pregnancy: {
        title: 'Pregnancy',
    }
}